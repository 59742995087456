// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".index-module__zAQLyfOQTfnoD5LFYe84 {\n  margin-bottom: 15px;\n}\n\n.index-module__hNkKSstWB1LOSBadhY1W {\n  font-size: 16px;\n}\n\n.index-module__V59TQ8maexZrLdgICAc9 {\n  display: block;\n}\n\n.index-module__uBVyQr0VLLxM5LaOMsQK {\n  margin-top: 24px;\n}\n\n.index-module__YQaeLyBu65k2XGq8khc9 {\n  margin-top: 8px;\n}\n\n.index-module__HeLNG9PORiCLlHnj8VN5 {\n  display: block;\n  border-radius: 2px;\n  border: var(--border);\n  padding: 4px;\n  background-color: var(--secondary-background);\n  overflow-wrap: break-word;\n}\n\n.index-module__OGpFy1uuRSXze8YUvd5Q {\n  color: var(--warning-text-color);\n}\n\n.index-module__utHz2TjcauFO3PZiryfD {\n  color: var(--error-text-color);\n}\n", "",{"version":3,"sources":["webpack://./containers/UserSettings/parts/connectors/index.module.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,eAAe;AACjB;;AAEA;EACE,cAAc;EACd,kBAAkB;EAClB,qBAAqB;EACrB,YAAY;EACZ,6CAA6C;EAC7C,yBAAyB;AAC3B;;AAEA;EACE,gCAAgC;AAClC;;AAEA;EACE,8BAA8B;AAChC","sourcesContent":[".user-item {\n  margin-bottom: 15px;\n}\n\n.user-value {\n  font-size: 16px;\n}\n\n.iCal-settings {\n  display: block;\n}\n\n.iCal-button {\n  margin-top: 24px;\n}\n\n.iCal-link-container {\n  margin-top: 8px;\n}\n\n.iCal-link {\n  display: block;\n  border-radius: 2px;\n  border: var(--border);\n  padding: 4px;\n  background-color: var(--secondary-background);\n  overflow-wrap: break-word;\n}\n\n.warning-icon {\n  color: var(--warning-text-color);\n}\n\n.error-message {\n  color: var(--error-text-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"user-item": "index-module__zAQLyfOQTfnoD5LFYe84",
	"user-value": "index-module__hNkKSstWB1LOSBadhY1W",
	"iCal-settings": "index-module__V59TQ8maexZrLdgICAc9",
	"iCal-button": "index-module__uBVyQr0VLLxM5LaOMsQK",
	"iCal-link-container": "index-module__YQaeLyBu65k2XGq8khc9",
	"iCal-link": "index-module__HeLNG9PORiCLlHnj8VN5",
	"warning-icon": "index-module__OGpFy1uuRSXze8YUvd5Q",
	"error-message": "index-module__utHz2TjcauFO3PZiryfD"
};
export default ___CSS_LOADER_EXPORT___;
