// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".ChannelFilterForm-module__M7HYSboLD3L8nX2hBHxl {\n  margin: 20px 0;\n}\n\n.ChannelFilterForm-module__vn6dcbWdrQ4EarJ8USfl {\n  margin: 20px 0;\n}\n", "",{"version":3,"sources":["webpack://./containers/ChannelFilterForm/ChannelFilterForm.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,cAAc;AAChB","sourcesContent":[".form {\n  margin: 20px 0;\n}\n\n.incident-matcher {\n  margin: 20px 0;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"form": "ChannelFilterForm-module__M7HYSboLD3L8nX2hBHxl",
	"incident-matcher": "ChannelFilterForm-module__vn6dcbWdrQ4EarJ8USfl"
};
export default ___CSS_LOADER_EXPORT___;
