// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OutgoingWebhook2Form-module__J3mU_SpdXP_dmKjgiaYc {\n  display: block;\n}\n\n.OutgoingWebhook2Form-module__gjLtAwWezZFlIee9W9xO {\n  margin: 16px 0 0 16px;\n}\n\n.OutgoingWebhook2Form-module__fol7l0VXOW1PlITZm92S {\n  margin: 4px;\n}\n", "",{"version":3,"sources":["webpack://./containers/OutgoingWebhook2Form/OutgoingWebhook2Form.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".root {\n  display: block;\n}\n\n.title {\n  margin: 16px 0 0 16px;\n}\n\n.content {\n  margin: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "OutgoingWebhook2Form-module__J3mU_SpdXP_dmKjgiaYc",
	"title": "OutgoingWebhook2Form-module__gjLtAwWezZFlIee9W9xO",
	"content": "OutgoingWebhook2Form-module__fol7l0VXOW1PlITZm92S"
};
export default ___CSS_LOADER_EXPORT___;
