// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".CollapsedIntegrationRouteDisplay-module__uwIeJlZbEl32W9wQUvZa{margin-bottom:12px}.CollapsedIntegrationRouteDisplay-module__y0diHp9UOty0TqYpPSRb{color:var(--error-text-color)}.CollapsedIntegrationRouteDisplay-module__Ny9Co0seP7orJsanmdb4{width:100%;display:flex;flex-direction:row;flex-wrap:nowrap;overflow:hidden;gap:12px}.CollapsedIntegrationRouteDisplay-module__JEJk1fW7BOuHI3o9Pws1{display:flex;white-space:nowrap;flex-direction:row;gap:8px}.CollapsedIntegrationRouteDisplay-module__vgCcRBuGjFEqgmOhRpYy{flex-grow:1;overflow:hidden}.CollapsedIntegrationRouteDisplay-module__j7P04n6dCcekR5Q5Sy3r{overflow:hidden;max-width:calc(100% - 48px);text-overflow:ellipsis}", "",{"version":3,"sources":["webpack://./containers/IntegrationContainers/CollapsedIntegrationRouteDisplay/CollapsedIntegrationRouteDisplay.module.scss"],"names":[],"mappings":"AAAA,+DACE,kBAAA,CAGF,+DACE,6BAAA,CAGF,+DACE,UAAA,CACA,YAAA,CACA,kBAAA,CACA,gBAAA,CACA,eAAA,CACA,QAAA,CAEA,+DACE,YAAA,CACA,kBAAA,CACA,kBAAA,CACA,OAAA,CAGF,+DACE,WAAA,CACA,eAAA,CAGF,+DACE,eAAA,CACA,2BAAA,CACA,sBAAA","sourcesContent":[".spacing {\n  margin-bottom: 12px;\n}\n\n.icon-exclamation {\n  color: var(--error-text-color);\n}\n\n.heading-container {\n  width: 100%;\n  display: flex;\n  flex-direction: row;\n  flex-wrap: nowrap;\n  overflow: hidden;\n  gap: 12px;\n\n  &__item {\n    display: flex;\n    white-space: nowrap;\n    flex-direction: row;\n    gap: 8px;\n  }\n\n  &__item--large {\n    flex-grow: 1;\n    overflow: hidden;\n  }\n\n  &__text {\n    overflow: hidden;\n    max-width: calc(100% - 48px);\n    text-overflow: ellipsis;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"spacing": "CollapsedIntegrationRouteDisplay-module__uwIeJlZbEl32W9wQUvZa",
	"icon-exclamation": "CollapsedIntegrationRouteDisplay-module__y0diHp9UOty0TqYpPSRb",
	"heading-container": "CollapsedIntegrationRouteDisplay-module__Ny9Co0seP7orJsanmdb4",
	"heading-container__item": "CollapsedIntegrationRouteDisplay-module__JEJk1fW7BOuHI3o9Pws1",
	"heading-container__item--large": "CollapsedIntegrationRouteDisplay-module__vgCcRBuGjFEqgmOhRpYy",
	"heading-container__text": "CollapsedIntegrationRouteDisplay-module__j7P04n6dCcekR5Q5Sy3r"
};
export default ___CSS_LOADER_EXPORT___;
