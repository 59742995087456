// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".Collapse-module__OIowc7rWYzbQgEkP1npQ {\n  border: var(--border);\n}\n\n.Collapse-module__nhdlLnawyutVdAEDlPMI {\n  padding: 8px;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n}\n\n.Collapse-module__BmDDqScL3CMWjI1_hpCB {\n  background: var(--secondary-background);\n}\n\n.Collapse-module__rvI4OT24R3m4CsKhkIrP {\n  display: block;\n  margin-left: 8px;\n  flex-grow: 1;\n}\n\n.Collapse-module__n1sXOxGYKG_wh5VxXrwQ {\n  padding: 16px;\n}\n\n.Collapse-module__lNVO7Ls2oagQW7nTM21Q {\n  color: var(--secondary-text-color);\n}\n", "",{"version":3,"sources":["webpack://./components/Collapse/Collapse.module.css"],"names":[],"mappings":"AAAA;EACE,qBAAqB;AACvB;;AAEA;EACE,YAAY;EACZ,eAAe;EACf,aAAa;EACb,mBAAmB;AACrB;;AAEA;EACE,uCAAuC;AACzC;;AAEA;EACE,cAAc;EACd,gBAAgB;EAChB,YAAY;AACd;;AAEA;EACE,aAAa;AACf;;AAEA;EACE,kCAAkC;AACpC","sourcesContent":[".root {\n  border: var(--border);\n}\n\n.header {\n  padding: 8px;\n  cursor: pointer;\n  display: flex;\n  align-items: center;\n}\n\n.header_with-background {\n  background: var(--secondary-background);\n}\n\n.label {\n  display: block;\n  margin-left: 8px;\n  flex-grow: 1;\n}\n\n.content {\n  padding: 16px;\n}\n\n.icon {\n  color: var(--secondary-text-color);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "Collapse-module__OIowc7rWYzbQgEkP1npQ",
	"header": "Collapse-module__nhdlLnawyutVdAEDlPMI",
	"header_with-background": "Collapse-module__BmDDqScL3CMWjI1_hpCB",
	"label": "Collapse-module__rvI4OT24R3m4CsKhkIrP",
	"content": "Collapse-module__n1sXOxGYKG_wh5VxXrwQ",
	"icon": "Collapse-module__lNVO7Ls2oagQW7nTM21Q"
};
export default ___CSS_LOADER_EXPORT___;
