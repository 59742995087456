// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".UserInfoTab-module__GoF7vhEmcck5bZypWnLR {\n  font-size: 16px;\n}\n\n.UserInfoTab-module__OOUM6ZByK3J4oWJM4KgJ {\n  margin-bottom: 15px;\n}\n", "",{"version":3,"sources":["webpack://./containers/UserSettings/parts/tabs/UserInfoTab/UserInfoTab.module.css"],"names":[],"mappings":"AAAA;EACE,eAAe;AACjB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".user-value {\n  font-size: 16px;\n}\n\n.user-item {\n  margin-bottom: 15px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"user-value": "UserInfoTab-module__GoF7vhEmcck5bZypWnLR",
	"user-item": "UserInfoTab-module__OOUM6ZByK3J4oWJM4KgJ"
};
export default ___CSS_LOADER_EXPORT___;
