// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OutgoingWebhookForm-module__ooibJNolYY9E9ctVwBzr {\n  display: block;\n}\n\n.OutgoingWebhookForm-module__X5GzsxTNVuczp5WKeYH2 {\n  margin: 16px 0 0 16px;\n}\n\n.OutgoingWebhookForm-module__FTedBozUSBqqEV_lAAWL {\n  margin: 4px;\n}\n", "",{"version":3,"sources":["webpack://./containers/OutgoingWebhookForm/OutgoingWebhookForm.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,WAAW;AACb","sourcesContent":[".root {\n  display: block;\n}\n\n.title {\n  margin: 16px 0 0 16px;\n}\n\n.content {\n  margin: 4px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "OutgoingWebhookForm-module__ooibJNolYY9E9ctVwBzr",
	"title": "OutgoingWebhookForm-module__X5GzsxTNVuczp5WKeYH2",
	"content": "OutgoingWebhookForm-module__FTedBozUSBqqEV_lAAWL"
};
export default ___CSS_LOADER_EXPORT___;
