// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IntegrationSettings-module__mXxsUZDOP6JxUTjmCzrR {\n  display: block;\n}\n\n.IntegrationSettings-module__etq86AFVJq66F5E9niDl {\n  padding: 24px;\n  display: flex;\n  gap: 10px;\n  align-items: flex-start;\n}\n\n.IntegrationSettings-module__UhBCo8pq3WRdBAujFgN_ {\n  display: flex;\n  flex-direction: column;\n}\n\n.IntegrationSettings-module__La9PjAIIe3TjkP4t8nwp {\n  margin-top: 10px;\n}\n\n.IntegrationSettings-module__La9PjAIIe3TjkP4t8nwp ul {\n  list-style-type: none;\n}\n\n.IntegrationSettings-module__La9PjAIIe3TjkP4t8nwp ol {\n  list-style-type: none;\n}\n\n.IntegrationSettings-module__kRspuWme8WW7KdKmrobu {\n  right: 0;\n  position: absolute;\n}\n", "",{"version":3,"sources":["webpack://./containers/IntegrationSettings/IntegrationSettings.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,aAAa;EACb,SAAS;EACT,uBAAuB;AACzB;;AAEA;EACE,aAAa;EACb,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,QAAQ;EACR,kBAAkB;AACpB","sourcesContent":[".root {\n  display: block;\n}\n\n.title {\n  padding: 24px;\n  display: flex;\n  gap: 10px;\n  align-items: flex-start;\n}\n\n.title-column {\n  display: flex;\n  flex-direction: column;\n}\n\n.content {\n  margin-top: 10px;\n}\n\n.content ul {\n  list-style-type: none;\n}\n\n.content ol {\n  list-style-type: none;\n}\n\n.settings-header-buttons {\n  right: 0;\n  position: absolute;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "IntegrationSettings-module__mXxsUZDOP6JxUTjmCzrR",
	"title": "IntegrationSettings-module__etq86AFVJq66F5E9niDl",
	"title-column": "IntegrationSettings-module__UhBCo8pq3WRdBAujFgN_",
	"content": "IntegrationSettings-module__La9PjAIIe3TjkP4t8nwp",
	"settings-header-buttons": "IntegrationSettings-module__kRspuWme8WW7KdKmrobu"
};
export default ___CSS_LOADER_EXPORT___;
