// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OutgoingWebhooks2-module__lGNVY3HFrXgOrabA2UxC {\n  display: flex;\n  align-items: center;\n  width: 100%;\n  padding-top: 12px;\n}\n\n.OutgoingWebhooks2-module__X0iVnfFHjPLfkkCu2coQ {\n  display: flex;\n  align-items: baseline;\n}\n\n.OutgoingWebhooks2-module__mRSwDVkAgdWsddg4C5Ht {\n  margin-bottom: 12px;\n}\n", "",{"version":3,"sources":["webpack://./pages/outgoing_webhooks_2/OutgoingWebhooks2.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,mBAAmB;EACnB,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,aAAa;EACb,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".header {\n  display: flex;\n  align-items: center;\n  width: 100%;\n  padding-top: 12px;\n}\n\n.header__title {\n  display: flex;\n  align-items: baseline;\n}\n\n.header__desc {\n  margin-bottom: 12px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "OutgoingWebhooks2-module__lGNVY3HFrXgOrabA2UxC",
	"header__title": "OutgoingWebhooks2-module__X0iVnfFHjPLfkkCu2coQ",
	"header__desc": "OutgoingWebhooks2-module__mRSwDVkAgdWsddg4C5Ht"
};
export default ___CSS_LOADER_EXPORT___;
