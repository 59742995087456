// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OrganizationLogFilters-module__Qwq_WHPsTFxg60TNFjoc {\n  display: block;\n}\n\n.OrganizationLogFilters-module__Qwq_WHPsTFxg60TNFjoc > * {\n  margin-bottom: 10px !important;\n}\n\n.OrganizationLogFilters-module__Qwq_WHPsTFxg60TNFjoc > *:not(:last-child) {\n  margin-right: 10px !important;\n}\n\n.OrganizationLogFilters-module__Qwq_WHPsTFxg60TNFjoc .OrganizationLogFilters-module__s2hbd2akYreA0jQkSeAt {\n  width: 400px;\n}\n\n.OrganizationLogFilters-module__ACRvian9oXzzD9kjwWJE {\n  min-width: 300px;\n}\n", "",{"version":3,"sources":["webpack://./containers/OrganizationLogFilters/OrganizationLogFilters.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,8BAA8B;AAChC;;AAEA;EACE,6BAA6B;AAC/B;;AAEA;EACE,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".root {\n  display: block;\n}\n\n.root > * {\n  margin-bottom: 10px !important;\n}\n\n.root > *:not(:last-child) {\n  margin-right: 10px !important;\n}\n\n.root .search {\n  width: 400px;\n}\n\n.select {\n  min-width: 300px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "OrganizationLogFilters-module__Qwq_WHPsTFxg60TNFjoc",
	"search": "OrganizationLogFilters-module__s2hbd2akYreA0jQkSeAt",
	"select": "OrganizationLogFilters-module__ACRvian9oXzzD9kjwWJE"
};
export default ___CSS_LOADER_EXPORT___;
