// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".HeartbeatForm-module__nRE_MIVcNtMKdRFC7xb0 {\n  width: 200px;\n}\n\n.HeartbeatForm-module__bhOhzEonAnXQJhOnslj2 {\n  width: 14px;\n  height: 14px;\n  border-radius: 50%;\n  margin-right: 8px;\n  display: inline-block;\n  background-color: grey;\n}\n\n.HeartbeatForm-module__DmBuO5J0ilfWUgpYkjHU {\n  background-color: #ff4d4f;\n}\n\n.HeartbeatForm-module__AfSKDCOkAaM8iF_YCO8X {\n  background-color: #73d13d;\n}\n\n.HeartbeatForm-module__CUDv5KDXiozTfMyarsoc .HeartbeatForm-module__nRE_MIVcNtMKdRFC7xb0 {\n  width: 250px;\n  display: inline-block;\n}\n\n.HeartbeatForm-module__uH7eBxkjXc0dEiOIviHn {\n  margin-top: 20px;\n}\n", "",{"version":3,"sources":["webpack://./containers/HeartbeatModal/HeartbeatForm.module.css"],"names":[],"mappings":"AAAA;EACE,YAAY;AACd;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,iBAAiB;EACjB,qBAAqB;EACrB,sBAAsB;AACxB;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,YAAY;EACZ,qBAAqB;AACvB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":[".select {\n  width: 200px;\n}\n\n.heartbeat-button {\n  width: 14px;\n  height: 14px;\n  border-radius: 50%;\n  margin-right: 8px;\n  display: inline-block;\n  background-color: grey;\n}\n\n.heartbeat-button_false {\n  background-color: #ff4d4f;\n}\n\n.heartbeat-button_true {\n  background-color: #73d13d;\n}\n\n.root .select {\n  width: 250px;\n  display: inline-block;\n}\n\n.buttons {\n  margin-top: 20px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"select": "HeartbeatForm-module__nRE_MIVcNtMKdRFC7xb0",
	"heartbeat-button": "HeartbeatForm-module__bhOhzEonAnXQJhOnslj2",
	"heartbeat-button_false": "HeartbeatForm-module__DmBuO5J0ilfWUgpYkjHU",
	"heartbeat-button_true": "HeartbeatForm-module__AfSKDCOkAaM8iF_YCO8X",
	"root": "HeartbeatForm-module__CUDv5KDXiozTfMyarsoc",
	"buttons": "HeartbeatForm-module__uH7eBxkjXc0dEiOIviHn"
};
export default ___CSS_LOADER_EXPORT___;
