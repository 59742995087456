// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".IncidentMatcher-module__Tw1CqDO9sRfSQknxWVbb {\n  display: block;\n}\n\n.IncidentMatcher-module__xMU1YaHxKCjhIVyJ1DmL {\n  display: flex;\n  gap: 10px;\n  align-items: flex-start;\n  width: 100%;\n}\n\n.IncidentMatcher-module__xMU1YaHxKCjhIVyJ1DmL > div {\n  width: 50%;\n}\n\n.IncidentMatcher-module__Q0DlDQoxFCt2BRMEIem0 {\n  list-style-type: none;\n}\n\n.IncidentMatcher-module__X8u_1DjIGVeJF0Zg8EqM {\n  margin-bottom: 10px;\n}\n", "",{"version":3,"sources":["webpack://./containers/IncidentMatcher/IncidentMatcher.module.css"],"names":[],"mappings":"AAAA;EACE,cAAc;AAChB;;AAEA;EACE,aAAa;EACb,SAAS;EACT,uBAAuB;EACvB,WAAW;AACb;;AAEA;EACE,UAAU;AACZ;;AAEA;EACE,qBAAqB;AACvB;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".root {\n  display: block;\n}\n\n.columns {\n  display: flex;\n  gap: 10px;\n  align-items: flex-start;\n  width: 100%;\n}\n\n.columns > div {\n  width: 50%;\n}\n\n.incident-item-list {\n  list-style-type: none;\n}\n\n.title {\n  margin-bottom: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": "IncidentMatcher-module__Tw1CqDO9sRfSQknxWVbb",
	"columns": "IncidentMatcher-module__xMU1YaHxKCjhIVyJ1DmL",
	"incident-item-list": "IncidentMatcher-module__Q0DlDQoxFCt2BRMEIem0",
	"title": "IncidentMatcher-module__X8u_1DjIGVeJF0Zg8EqM"
};
export default ___CSS_LOADER_EXPORT___;
