// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".EditRegexpRouteTemplateModal-module__rcTVMFX_k0HZ6Gc1dH3J {\n  width: 100%;\n}\n\n.EditRegexpRouteTemplateModal-module__KPCF3t4GK9RtFuoD0bl4 {\n  width: 700px;\n}\n", "",{"version":3,"sources":["webpack://./containers/EditRegexpRouteTemplateModal/EditRegexpRouteTemplateModal.module.css"],"names":[],"mappings":"AAAA;EACE,WAAW;AACb;;AAEA;EACE,YAAY;AACd","sourcesContent":[".regexp-template-code {\n  width: 100%;\n}\n\n.regexp-template-editor-modal {\n  width: 700px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"regexp-template-code": "EditRegexpRouteTemplateModal-module__rcTVMFX_k0HZ6Gc1dH3J",
	"regexp-template-editor-modal": "EditRegexpRouteTemplateModal-module__KPCF3t4GK9RtFuoD0bl4"
};
export default ___CSS_LOADER_EXPORT___;
