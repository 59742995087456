// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".OrganizationLog-module__NLAMLnx9J2CTFa7lqCwA {\n  display: flex;\n  justify-content: space-between;\n}\n\n.OrganizationLog-module__rB_JgKFtefNFVcTWtDLe {\n  vertical-align: top;\n}\n\n.OrganizationLog-module__CRx6CcGD9UV5Rl3ZrT3T {\n  background: #fff7e6;\n}\n\n.OrganizationLog-module__eGgTKy2QN1nIHgK06iZB .OrganizationLog-module__tMOVpErevCRj_dQ1knjh {\n  background: none;\n}\n\n.OrganizationLog-module__eGgTKy2QN1nIHgK06iZB .OrganizationLog-module__bnheD8hcbXiV98g5QQBi {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  width: 400px;\n  white-space: nowrap;\n}\n", "",{"version":3,"sources":["webpack://./pages/organization-logs/OrganizationLog.module.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,8BAA8B;AAChC;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,gBAAgB;EAChB,uBAAuB;EACvB,YAAY;EACZ,mBAAmB;AACrB","sourcesContent":[".header {\n  display: flex;\n  justify-content: space-between;\n}\n\n.align-top {\n  vertical-align: top;\n}\n\n.no-author {\n  background: #fff7e6;\n}\n\n.root .no-background {\n  background: none;\n}\n\n.root .short-description {\n  overflow: hidden;\n  text-overflow: ellipsis;\n  width: 400px;\n  white-space: nowrap;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"header": "OrganizationLog-module__NLAMLnx9J2CTFa7lqCwA",
	"align-top": "OrganizationLog-module__rB_JgKFtefNFVcTWtDLe",
	"no-author": "OrganizationLog-module__CRx6CcGD9UV5Rl3ZrT3T",
	"root": "OrganizationLog-module__eGgTKy2QN1nIHgK06iZB",
	"no-background": "OrganizationLog-module__tMOVpErevCRj_dQ1knjh",
	"short-description": "OrganizationLog-module__bnheD8hcbXiV98g5QQBi"
};
export default ___CSS_LOADER_EXPORT___;
